import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container, Button, Stack } from "react-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import Space from "../components/Space.jsx";
import Nominees from "../components/Nominees.jsx";
import Raffle from "../components/Raffle.jsx";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const view = params.get("view") || "bidrag";
    setActiveTab(view === "utlottning" ? 1 : 0);
  }, [location.search]);

  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });
    navigate({ search: searchParams.toString() });
  };

  const handleTabSelect = (index) => {
    setActiveTab(index);
    updateQueryParams({ view: index === 1 ? "utlottning" : "bidrag" });
  };

  const logout = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signOut(auth);
  };

  return (
    <>
      <Container>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ margin: "0 auto 50px", width: "21%", color: "#fff" }}>
            MILJONLOTTERIET
          </div>
        </div>
        <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
          <TabList
            style={{ padding: "40px 0", backgroundColor: "", overflow: "auto" }}
          >
            <Stack direction="horizontal" gap={2}>
              <Tab>Bidrag</Tab>
              <Tab>Utlottning</Tab>
              <Button className="ms-auto" variant="dark" onClick={logout}>
                Logga ut
              </Button>
            </Stack>
          </TabList>
          <Space />
          <TabPanel style={{ overflow: "auto" }}>
            <Nominees />
          </TabPanel>
          <TabPanel>
            <Raffle />
          </TabPanel>
        </Tabs>
        <Space height="5vw" />
      </Container>
    </>
  );
};

export default Home;

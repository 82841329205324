import React, { useEffect, useState, useContext } from "react";
import API from "../apienv.js";
import { AuthContext } from "../auth.jsx";
import Tables from "./Tables.jsx";
import { formatDataArray } from "../helpers.js";
import { filterNomineesQuery } from "../utils/filterUtils.js"; // Import the utility function

const headers = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Skapad",
    accessor: "created_at",
  },
];

const Competition = ({ competitionId, fromDate, toDate }) => {
  const { currentUser } = useContext(AuthContext);
  const [signups, setSignups] = useState(false);

  const getData = async () => {
    const token = await currentUser.getIdToken();
    const query = filterNomineesQuery(
      "approved",
      fromDate,
      toDate,
      competitionId
    ); // Use the utility function

    const response = await fetch(
      `${API}/admin/signups/${competitionId}?${new URLSearchParams(
        query
      ).toString()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = await response.json();
    setSignups(formatDataArray(responseData));
  };

  useEffect(() => {
    getData();
  }, [fromDate, toDate]);

  return <>{signups && <Tables c={headers} d={signups} />}</>;
};

export default Competition;

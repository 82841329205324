import React from "react";
import { Modal, Button, Form, CloseButton } from "react-bootstrap";
import ReactModal from "react-modal";
import API from "../apienv";

const NomineeModal = ({
  openNominee,
  modalIsOpen,
  closeModal,
  approveNominee,
}) => {
  const [status, setStatus] = React.useState(0);

  const submit = async (e) => {
    e.preventDefault();
    await approveNominee(openNominee._id, status).then((success) => {
      if (success) {
        closeModal();
      }
    });
  };

  return (
    <ReactModal
      ariaHideApp={false}
      key={openNominee}
      tabindex="-1"
      role="dialog"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      class="modal"
      overlayClassName="_"
      contentElement={(props, children) => (
        <div
          {...props}
          style={{
            ...props.style,
            position: "fixed",
            inset: 0,
            padding: "60px",
          }}
        >
          <Modal.Header style={{ justifyContent: "space-between" }}>
            <h4></h4>
            <CloseButton
              onClick={closeModal}
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={submit}>
              {openNominee.fileName &&
              typeof openNominee.fileName === "string" &&
              openNominee.fileName.endsWith(".pdf") ? (
                <a
                  href={`${API}/${openNominee.fileName}`}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {openNominee.fileName}
                </a>
              ) : (
                <img
                  style={{
                    maxWidth: "1000px",
                    width: "100%",
                    height: "auto",
                    margin: "0 auto",
                  }}
                  src={`${API}/${openNominee.fileName}`}
                  alt="nominee"
                />
              )}

              <ul style={{ marginTop: "40px", marginBottom: "100px" }}>
                <li>
                  <strong>Name:</strong> {openNominee.name}
                </li>
                <li>
                  <strong>Name of the nominee:</strong>{" "}
                  {openNominee.nomineeName}
                </li>
                <li>
                  <strong>Email:</strong> {openNominee.email}
                </li>
                <li>
                  <strong>Phone:</strong> {openNominee.phone}
                </li>
                <li>
                  <strong>Text:</strong> {openNominee.text}
                </li>
                <li>
                  <strong>CompetitionId:</strong> {openNominee.competitionId}
                </li>
              </ul>

              <Modal.Footer
                style={{
                  padding: "40px 20px",
                  justifyContent: "flex-start",
                  borderTop: "1px solid",
                }}
              >
                <Button
                  variant="dark"
                  style={{
                    backgroundColor: "#ACAF2E",
                    border: "#ACAF2E",
                  }}
                  type="submit"
                  onClick={() => setStatus("approved")}
                >
                  Godkänn
                </Button>
                <Button
                  style={{
                    backgroundColor: "#ad2e2e",
                    border: "#ad2e2e",
                    marginLeft: "20px",
                  }}
                  variant="dark"
                  type="submit"
                  onClick={() => setStatus("rejected")}
                >
                  Neka
                </Button>
                <Button
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#ebc634",
                    border: "#ad2e2e",
                    marginLeft: "20px",
                  }}
                >
                  Stäng
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </div>
      )}
    ></ReactModal>
  );
};

export default NomineeModal;

import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Competition from "./Competition.jsx";
import Space from "./Space.jsx";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ExportButton from "./ExportButton.jsx";

const competitionTabs = [
  { id: "sveriges-storsta-bakproffs", label: "Bakproffs" },
  { id: "sveriges-storsta-hockeyfantast", label: "Hockeyfantast" },
  { id: "sveriges-storsta-julalskare", label: "Julälskare" },
  { id: "sveriges-storsta-djurvan", label: "Djurvän" },
];

const Raffle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get("from") || "";
    const to = params.get("to") || "";
    const competitionId = params.get("competitionId") || competitionTabs[0].id;

    setFromDate(from);
    setToDate(to);
    const tabIndex = competitionTabs.findIndex(
      (tab) => tab.id === competitionId
    );
    setActiveTab(tabIndex !== -1 ? tabIndex : 0);
  }, [location.search]);

  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });
    navigate({ search: searchParams.toString() });
  };

  const handleTabSelect = (index) => {
    setActiveTab(index);
    updateQueryParams({ competitionId: competitionTabs[index].id });
  };

  return (
    <>
      <Form>
        <Row className="align-items-end">
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  updateQueryParams({ from: e.target.value });
                }}
              />
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  updateQueryParams({ to: e.target.value });
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <ExportButton type="raffle" />
          </Col>
        </Row>
      </Form>
      <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
        <TabList style={{ backgroundColor: "", overflow: "auto" }}>
          <Stack direction="horizontal" gap={2}>
            {competitionTabs.map((tab, index) => (
              <Tab key={index}>{tab.label}</Tab>
            ))}
          </Stack>
        </TabList>
        {competitionTabs.map((tab, index) => (
          <TabPanel key={index}>
            <Competition
              competitionId={tab.id}
              fromDate={fromDate}
              toDate={toDate}
            />
          </TabPanel>
        ))}
      </Tabs>
      <Space height="5vw" />
    </>
  );
};

export default Raffle;

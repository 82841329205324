import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import Tables from "./Tables.jsx";
import NomineeModal from "./NomineeModal.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../auth.jsx";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { formatDataArray } from "../helpers.js";
import API from "../apienv";
import ExportButton from "./ExportButton.jsx";
import { filterNomineesQuery } from "../utils/filterUtils.js";

const Nominees = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [openNominee, setOpenNominee] = React.useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [competitionId, setCompetitionId] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [statistics, setStatistics] = useState({
    totalVotes: 0,
    uniqueVoters: 0,
    totalNominees: 0,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get("from") || "";
    const to = params.get("to") || "";
    const competition = params.get("competitionId") || "";
    const status = params.get("status") || "pending";

    setFromDate(from);
    setToDate(to);
    setCompetitionId(competition);
    setStatusFilter(status);
  }, [location.search]);

  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });
    navigate({ search: searchParams.toString() });
  };

  const getData = useCallback(async () => {
    const token = await currentUser.getIdToken();
    const query = filterNomineesQuery(
      statusFilter,
      fromDate,
      toDate,
      competitionId
    ); // Use the utility function

    return await fetch(
      `${API}/admin/nominees/filter/${statusFilter}?${new URLSearchParams(
        query
      ).toString()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const formatedResponse = formatDataArray(response.nominees);
        setAdminData(formatedResponse);
        setStatistics({
          totalVotes: response.totalVotes,
          uniqueVoters: response.uniqueVoters,
          totalNominees: response.totalNominees,
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  }, [competitionId, currentUser, fromDate, statusFilter, toDate]);

  useEffect(() => {
    if (
      fromDate !== "" ||
      toDate !== "" ||
      competitionId !== "" ||
      statusFilter !== null
    ) {
      getData();
    }
  }, [statusFilter, fromDate, toDate, competitionId, getData]);

  const openModal = (row) => {
    setIsOpen(true);
    const recipeObj = row.original;
    setOpenNominee(recipeObj);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const approveNominee = useCallback(
    async (nomineeId, status) => {
      const token = await currentUser.getIdToken();
      return await fetch(`${API}/admin/nominees/update/${nomineeId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      })
        .then((response) => response.json())
        .then((response) => {
          getData();
          return true;
        })
        .catch((err) => {
          console.log("Error:", err);
          return false;
        });
    },
    [currentUser, getData]
  );

  if (!adminData) {
    return <></>;
  }

  const theTable = (
    <Tables
      key={adminData}
      d={adminData}
      c={[
        {
          Header: "Name",
          accessor: "name", // accessor is the "key" in the data
        },
        {
          Header: "Nominee name",
          accessor: "nomineeName", // accessor is the "key" in the data
        },
        {
          Header: "Email",
          accessor: "email", // accessor is the "key" in the data
        },
        {
          Header: "Phone",
          accessor: "phone", // accessor is the "key" in the data
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Skapad",
          accessor: "created_at",
        },
        {
          Header: "Uppdaterad",
          accessor: "updated_at",
        },
      ]}
      onRowClick={openModal} // Add this line to handle row click
    />
  );

  return (
    <>
      <Form>
        <Row className="align-items-end">
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="statusFilter">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  updateQueryParams({ status: e.target.value });
                }}
              >
                <option value="pending">Att godkänna</option>
                <option value="approved">Godkända</option>
                <option value="rejected">Nekade</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  updateQueryParams({ from: e.target.value });
                }}
              />
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  updateQueryParams({ to: e.target.value });
                }}
              />
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: "300px" }}>
            <Form.Group controlId="competitionId">
              <Form.Label>Competition</Form.Label>
              <Form.Control
                as="select"
                value={competitionId}
                onChange={(e) => {
                  setCompetitionId(e.target.value);
                  updateQueryParams({ competitionId: e.target.value });
                }}
              >
                <option value="">All</option>
                <option value="sveriges-storsta-bakproffs">Bakproffs</option>
                <option value="sveriges-storsta-hockeyfantast">
                  Hockeyfantast
                </option>
                <option value="sveriges-storsta-julalskare">Julälskare</option>
                <option value="sveriges-storsta-djurvan">Djurvän</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={getData}>
              Filter
            </Button>
          </Col>
          <Col xs="auto">
            <ExportButton />
          </Col>
        </Row>
      </Form>
      <Row className="mt-4 mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Total Votes</Card.Title>
              <Card.Text>{statistics.totalVotes}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Unique Voters</Card.Title>
              <Card.Text>{statistics.uniqueVoters}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Total Nominees</Card.Title>
              <Card.Text>{statistics.totalNominees}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {adminData && <>{theTable}</>}
      {openNominee && (
        <NomineeModal
          openNominee={openNominee}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          approveNominee={approveNominee}
        />
      )}
    </>
  );
};

export default Nominees;

export const API = "http://localhost:3001";

export function formatStatus(status) {
  const r_status =
    status === "pending" ? (
      <span
        className="status"
        style={{
          backgroundColor: "#cdcf4a",
        }}
      >
        Att godkänna
      </span>
    ) : status === "approved" ? (
      <span
        className="status"
        style={{
          backgroundColor: "#6ead2e",
        }}
      >
        Godkänd
      </span>
    ) : (
      <span
        className="status"
        style={{
          backgroundColor: "#ad2e2e",
        }}
      >
        Nekad
      </span>
    );
  return r_status;
}

export function formatDataArray(response) {
  const formatedResponse = response.map((r) => {
    r.status = formatStatus(r.status);

    const ca = new Date(r.created_at);
    r.created_at = ca.toLocaleString();

    const ua = new Date(r.updated_at);
    r.updated_at = ua.toLocaleString();

    return r;
  });
  return formatedResponse;
}
